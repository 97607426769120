import React, { useState } from 'react'
import css from './sytel.module.css'

export default function SearchBox(props) {
    const { findText, text = "" } = props;

    const handle = (event) => {
        findText(event.target.value);
        // className="form-inline"  data-widget="sidebar-search"
    }

    return (
        <div>
            <div className="input-group">
                <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" onChange={handle} defaultValue={text}></input>
                <div className="input-group-append">
                    <button className="btn btn-sidebar" >
                        <i className="fas fa-search fa-fw" />
                    </button>
                </div>
            </div>
        </div>
    )
}
