export function getErrorDesc(error) {
    let desc = '';
    let err = error.response;
    if (err) {
        if (err.status) desc = '[' + err.status + '] ';
        if (err.statusText) desc = desc + err.statusText + ' ';
        if (err.data && err.data.message) desc = desc + " - " + err.data.message;

    }
    else {
        desc = error.message;
    }
    return desc;
}

export function getDate() {
    return date2str(new Date());//.split('T')[0];
}

export function date2str(dateVal) {
    let date = new Date(dateVal);
    let formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
    return formattedDate;
}

export function dateTime2str(dateTime) {
    let formattedDate = dateTime;
    try {
        let date = new Date(dateTime);

        // Format the date as desired
        formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
    }
    catch { }

    return formattedDate;
}

export function getFullName(r) {
    if (r)
        return r.firstname + " " + (r.middlename && r.middlename != null ? r.middlename + " " : "") + r.surname;
    else
        return '';
}