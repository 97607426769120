import React, { useEffect, useContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import ContactList from './ContactList'


export default function Contacts(props) {
    return (
        <Routes>
            <Route path="/" element={<ContactList />} />
        </Routes>
    )
}
