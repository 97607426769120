import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios, { fileAxios } from '../../../../my-axios';
import UserContext from '../../../../contexts/UserContext';
import ContentContext from '../../../../contexts/ContentContext';
import { Button } from 'react-bootstrap';
import MsgBox from '../../../controls/MsgBox';
import Spinner from '../../../controls/Spinner';
import PopupPage from '../../../controls/PopupPage';
import { getErrorDesc, date2str, dateTime2str, getFullName } from '../../../../utils/common';
import Reminders from './Reminders';

const URL = '/appointment';
const initialState = {
    msg: null,
    loading: false,
    success: false
};

const initialAppointment = {
    id: null,
    date: '',
    duration: '',
    detail: '',
    note: '',
    dob: '',
    street: '',
    suburb: '',
    state: '',
    status: 1,
    firstname: null,
    surname: null,
    middlename: null,
    uid: null,
    doctor_id: 'null',
    doctor_name: null,
    doctor_contact: null,
    doctor_email: null,
    doctor_address: null,
    files: null
}

export default function AppointmentDetail() {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const contentCtx = useContext(ContentContext);

    const { id } = useParams();
    const [state, setState] = useState(initialState);
    const [appointment, setAppointment] = useState(initialAppointment);
    const [showPopup, setShowPopup] = useState(false);
    const [showReminder, setShowReminder] = useState(false);
    const [showResendReminder, setShowResendReminder] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const [newStatus, setNewStatus] = useState(0);
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        contentCtx.updatePath([
            {
                title: 'Appointments',
                link: '/appointments'
            },
            {
                title: 'Appointment Detail',
                link: `/appointments/${id}`
            }]);
        loadData(id);
    }, [id]);

    const loadData = (id) => {
        setState({ ...state, loading: true, msg: null, success: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        axios.get(`${URL}/${id}`).then((result) => {
            var a = result.data;
            //console.log("GetFromServer", a);

            setAppointment({
                ...a, date: dateTime2str(a.date), date_create: date2str(a.date_create), date_change: dateTime2str(a.date_change),
                full_name: getFullName(a)
            });
            setFileName(a.files ? a.files : '');

            setState({ ...state, loading: false, msg: null });
        }).catch((error) => {
            console.log('error:', error);
            if (error.response && error.response.status == 401) {
                navigate("/signout");
            }
            else {
                setAppointment(initialAppointment);
                setState({
                    ...state,
                    loading: false, msg: getErrorDesc(error)
                });
            }
        });
    };

    const handleStatus = (status) => {
        setState({ ...state, msg: null, success: null });
        setNewStatus(status);
        setShowPopup(true);
    };

    const changeStatus = () => {
        setShowPopup(false);
        if (appointment && appointment.id) {
            var data = {
                id: appointment.id
            };
            var statusUrl = newStatus === 'CONFIRMED' ? `/appointment/${appointment.id}/confirm` : "/appointment/${appointment.id}/deconfirm";

            setState({ ...state, loading: true, msg: null, success: null });
            axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
            axios.get(statusUrl, data).then((result) => {
                //console.log(statusUrl + ": ", result.data);
                loadData(appointment.id);
                setState({ ...state, loading: false, msg: 'The appointment changed sucessfully', success: true });
            }).catch((error) => {
                console.log('confirm appointment error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error), success: false
                    });
                }
            });
        }
    };

    const updateNote = (val) => {
        setAppointment({ ...appointment, rep_note: val });
        setState({
            ...state,
            loading: false, msg: null, success: null
        });
    }

    const clickSave = () => {
        setState({ ...state, msg: null, success: null });
        setShowSave(true);
    }

    const save = () => {
        setShowSave(false);
        if (appointment && appointment.id) {
            var data = {
                app_id: appointment.id,
                rep_note: appointment.rep_note
            };

            setState({ ...state, loading: true, msg: null });
            axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
            axios.put(URL, data).then((result) => {
                //console.log(statusUrl + ": ", result.data);
                setState({ ...state, loading: false, msg: 'The note saved sucessfully', success: true });
            }).catch((error) => {
                console.log('save note appointment error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error), success: false
                    });
                }
            });
        }
    }

    const clickSendRemainder = () => {
        setState({ ...state, msg: null, success: null });
        setShowResendReminder(true);
    }

    const resendReminder = () => {
        setShowResendReminder(false);
        if (appointment && appointment.id) {
            var data = {
                appointmentId: appointment.id
            };
            var resendUrl = "/reminder";

            setState({ ...state, loading: true, msg: null, success: null });
            axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
            axios.put(resendUrl, data).then((result) => {
                //console.log(resendUrl + ": ", result.data);
                setState({ ...state, loading: false, msg: null });
            }).catch((error) => {
                console.log('resend appointment reminder error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
            });
        }
    };

    const clickShowReminder = () => {
        setState({ ...state, msg: null, success: null });
        setShowReminder(true);
    }

    const closeReminder = () => {
        setShowReminder(false);
    }

    const gotoPatient = (val) => {
        setState({ ...state, success: null, msg: null });
        navigate("/patients/" + val);
    };

    const gotoDoctor = (val) => {
        setState({ ...state, success: null, msg: null });
        navigate("/doctors/" + val);
    };

    const onFileChange = () => {
        setState({
            ...state,
            loading: false, msg: null
        });
        setFileName('');
        setFile(null);
    }

    const cancelFileChange = () => {
        setState({
            ...state,
            loading: false, msg: null
        });
        setFileName(appointment.files ? appointment.files : '');
    }

    const onFileChoose = (e) => {
        setState({
            ...state,
            loading: false, msg: null
        });
        let files = e.target.files;
        if (files && files.length > 0) {
            setFile(e.target.files);
        }
        else {
            setFile(null);
        }
        setState({
            ...state,
            loading: false, msg: null
        });
    };

    const onFileUpload = () => {
        if (!file) {
            setState({
                ...state,
                loading: false, msg: "Please choose files!"
            });
            return;
        }
        setState({
            ...state,
            loading: true, msg: null
        });
        var formData = new FormData();
        var myFiles = [];
        if (/^\[.*\]$/.test(file)) {
            myFiles = file;
        }
        else {
            myFiles.push(file);
        }
        //console.log("myFiles", myFiles);
        Object.keys(myFiles).forEach(key => {
            formData.append(myFiles.item(key).name, myFiles.item(key));
            //console.log(myFiles.item(key).name);
        });

        // for (var key of formData.entries()) {
        //     console.log(key[0] + ', ' + key[1]);
        // }
        // Update the formData object

        fileAxios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        fileAxios.post("/appointment/files/" + id, formData
        ).then((result) => {
            if (result.status === 200) {
                //setMessage("Uploaded " + result.data.message);
                loadData(id);
            }
        }).catch((error) => {
            console.log("Error onFileUpload", error.response.data.message);
            //setMessage(error.response.data.message);
            setState({
                ...state,
                loading: false, msg: error.response.data.message
            });
        });

    };

    const onFileDownload = () => {
        if (!fileName) {
            console.log("No saved file !");
            return;
        }
        setState({
            ...state,
            loading: false, msg: null
        });
        fileAxios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        fileAxios.get("/appointment/files/" + id, {
            responseType: 'blob'
        }
        ).then((result) => {
            if (result.status === 200) {
                console.log("File download success");
                //setMessage("Uploaded " + result.data.message);

                // Create a blob URL for the file content
                const blob = new Blob([result.data], { type: 'application/octet-stream' });
                const url = window.URL.createObjectURL(blob);

                // Create a link element
                const link = document.createElement('a');
                link.href = url;

                // Extract filename from response headers
                // const contentDisposition = result.headers['Content-Disposition'];
                // console.log("contentDisposition", contentDisposition);
                // const filename = contentDisposition.split(';')[1].trim().split('=')[1];
                const filename = appointment.files;

                // Set download attribute to the filename
                link.download = filename;

                // Append the link to the document body
                document.body.appendChild(link);

                // Programmatically click the link to trigger the download
                link.click();

                // Remove the link from the document body after download
                document.body.removeChild(link);

                // Revoke the blob URL to free up memory
                window.URL.revokeObjectURL(url);

                setState({
                    ...state,
                    loading: false, msg: null
                });
            }

        }).catch((error) => {
            console.log("Error onFileDownload", error);
            //setMessage(error.response.data.message);
            setState({
                ...state,
                loading: false, msg: getErrorDesc(error)
            });
        });
    }

    const handleBack = () => {
        setAppointment(initialAppointment);
        setState({ ...state, changing: false });
        navigate(-1);
    };

    return (

        <div>
            <div className="card-body">
                <Spinner value={state.loading} />

                <form role="form " >
                    <div className="row ">
                        <div className="col  mr-3">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repName" className="font-weight-normal">Date</label>
                                        <input id="repName" type="text" className="form-control" value={appointment.date} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group ">
                                        <label htmlFor="repDuration" className="font-weight-normal">Duration</label>
                                        <input id="repDuration" type="text" className="form-control" value={appointment.duration} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repDetail" className="font-weight-normal">Detail</label>
                                        <input id="repDetail" type="text" className="form-control" value={appointment.detail} readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col mr-3">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repPatient" className="font-weight-normal">Patient</label>
                                        <input id="repPatient" type="text" className="form-control" value={appointment.full_name} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repDoctor" className="font-weight-normal">Doctor</label>
                                        <input id="repDoctor" type="text" className="form-control" value={appointment.doctor_name} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="repAddress" className="font-weight-normal">Doctor Address</label>
                                    <input id="repAddress" type="text" className="form-control" value={appointment.doctor_address} readOnly />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repStatus" className="font-weight-normal">Status</label>
                                        <input id="repStatus" rows="2" type="text" className="form-control" value={appointment.status} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repCreate" className="font-weight-normal">Created Date</label>
                                        <input id="repCreate" type="text" className="form-control" value={appointment.date_create} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repChange" className="font-weight-normal">Updated Date</label>
                                        <input id="repChange" type="text" className="form-control" value={appointment.date_change} readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="genieNote" className="font-weight-normal">Doctor Note (<i>the patient can see</i>)</label>
                                <textarea id="genieNote" className="form-control" rows={2} value={appointment.doc_note} readOnly />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="repNote" className="font-weight-strong">Note (<i>the patient can see</i>)</label>
                                <textarea id="repNote" className="form-control" rows={3} value={appointment.rep_note} onChange={e => updateNote(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-end" >
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="repFile" className="form-label font-weight-normal">File</label>
                                {fileName
                                    ? <input id="repFile" type="text" readOnly className='form-control' value={fileName} />
                                    : <input id="repFile" type="file" className="form-control" onChange={onFileChoose} />}
                            </div>
                        </div>

                        <div className='col mb-3'>
                            {fileName
                                ? <>
                                    <Button variant="warning" onClick={onFileChange}>
                                        Change
                                    </Button >
                                    <Button variant="info" style={{ marginLeft: '10px' }} onClick={onFileDownload}>
                                        Download
                                    </Button >
                                </>
                                :
                                <>
                                    <Button variant="primary" onClick={onFileUpload}>
                                        Upload
                                    </Button>
                                    {appointment.files != fileName &&
                                        <Button variant="secondary" style={{ marginLeft: '10px', opacity: state.changing ? 1 : 0.5, pointerEvents: state.changing ? 'auto' : 'none'  }} onClick={cancelFileChange}>
                                            Cancel
                                        </Button >}
                                </>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {state.success && <MsgBox value={{ type: 'info', title: '', msg: state.msg }} />}
                            {!state.success && <MsgBox value={{ type: 'error', title: '', msg: state.msg }} />}
                        </div>
                    </div>
                    <div className="row mt-4" >
                        <div className="col-6 text-left">
                            {/* Back Button on the Left */}
                            <Button 
                                variant="secondary" 
                                onClick={handleBack} 
                                style={{ width: '100px', marginRight: '10px'  }}>
                                Back
                            </Button>
                            {appointment ? <Button variant="secondary" style={{ marginRight: '10px' }} onClick={() => gotoPatient(appointment.uid)}>Patient Detail</Button> : <></>}
                            {appointment ? <Button variant="secondary" style={{ marginRight: '10px' }} onClick={() => gotoDoctor(appointment.doctor_id)}>Doctor Detail</Button> : <></>}
                            {appointment ? <Button variant="secondary" onClick={() => clickShowReminder()}> Show Reminders</Button> : <></>}<></>
                        </div>
                        <div className="col text-right">
                            {appointment ? <Button variant="info" style={{ marginRight: '10px' }} onClick={() => clickSendRemainder()}>Resend reminder</Button> : <></>}<></>
                            {appointment && appointment.status !== 'CONFIRMED' ? <Button variant='danger' style={{ marginRight: '10px' }} onClick={() => handleStatus('CONFIRMED')}> Confirm</Button> : <></>}
                            {appointment ? <Button variant="primary" onClick={() => clickSave()}>Save Note</Button> : <></>}
                        </div>
                    </div>
                    <div className='row'>
                        <PopupPage
                            size="md"
                            openDialog={showReminder}
                            setOpenDialog={setShowReminder}
                            title={"Appointment Reminders"}
                            label='Ok'
                            onSave={closeReminder}
                            showCancel={false}>
                            <p>
                                <Reminders id={appointment.id} token={userCtx.getToken()} />
                            </p>
                        </PopupPage>
                        <PopupPage
                            size="md"
                            openDialog={showResendReminder}
                            setOpenDialog={setShowResendReminder}
                            title={"Resend Appointment Reminder"}
                            label='Yes'
                            onSave={resendReminder}
                            labelCancel='No'>
                            <p>Are you sure to resend appointment reminder?</p>
                        </PopupPage>
                        <PopupPage
                            size="md"
                            openDialog={showPopup}
                            setOpenDialog={setShowPopup}
                            title={appointment.status !== 'CONFIRMED' ? "Confirm Appointment" : "Diconfirm Appointment"}
                            label='Yes'
                            onSave={changeStatus}
                            labelCancel='No'>
                            <p>Are you sure to confirm this Appointment's status?</p>
                        </PopupPage>
                        <PopupPage
                            size="md"
                            openDialog={showSave}
                            setOpenDialog={setShowSave}
                            title={"Save Note"}
                            label='Yes'
                            onSave={save}
                            labelCancel='No'>
                            <p>Are you sure to save additional note to the patient?</p>
                        </PopupPage>
                    </div>
                </form>
            </div >
        </div >
    );
}
