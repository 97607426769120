import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import { UserStore } from './contexts/UserContext';
import { ContentStore } from './contexts/ContentContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  <BrowserRouter >
    <UserStore>
      <ContentStore>
        <App />
      </ContentStore>
    </UserStore>
  </BrowserRouter >

  //</React.StrictMode>
);
