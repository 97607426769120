import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { setToken } from '../../../../../my-axios';
import DataTable from '../../../../controls/DataTable';
import MsgBox from '../../../../controls/MsgBox';
import Spinner from '../../../../controls/Spinner';
import { getErrorDesc, date2str, dateTime2str, getDate } from '../../../../../utils/common';

const URL = "/reminder";
const titles = ["SENT", "TYPE"];
const cols = ["created_at", "type"];
const initState = {
    data: [],
    loading: false,
    msg: null
};

export default function Reminders({ id, token }) {
    console.log("Reminders: id=", id);
    const navigate = useNavigate();
    const [state, setState] = useState(initState);

    useEffect(() => {
        loadData(id);
    }, []);

    const loadData = (filterValues) => {
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        axios.get(URL + "/" + id).then((result) => {
            console.log("GetFromServer!!!", result.data);
            setState({
                ...state, data: formatData(result.data), loading: false, msg: null
            });
        }).catch((error) => {
            console.log('error:', error);
            if (error.response && error.response.status == 401) {
                navigate("/signout");
            }
            else {
                setState({
                    ...state, data: [], loading: false, msg: getErrorDesc(error)
                });
            }
        });
    };

    const formatData = (data) => {
        if (data)
            return data.map((r) => {
                return {
                    ...r,
                    created_at: dateTime2str(r.created_at)
                };
            });
        return data;
    };


    return (
        <div className="row mb-6 P-10" >
            <div className="col-12">
                <DataTable
                    module={URL}
                    id='created_at'
                    list={{
                        data: state.data,
                        columns: cols,
                        labels: titles
                    }}
                />
                <Spinner value={state.loading} />
                {state.msg !== null && <MsgBox value={{ type: 'error', title: '', msg: state.msg }} />}
            </div>
        </div>
    )
}
