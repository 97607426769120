import React from 'react';
import { Container, Modal } from 'react-bootstrap';

export default function PopupPage(props) {
    const { key, openDialog, setOpenDialog, size = 'lg', title, showSave = true, saveSize = 6, label = 'Save', onSave, showCancel = true, labelCancel = 'Cancel', children } = props;
    return <Modal
        key={key}
        size={size}
        show={openDialog}
        onHide={() => setOpenDialog(false)}
        backdrop='static'
        keyboard={false}
        aria-labelledby='modal-sizes-title-lg'
    >
        <Modal.Header>
            <Modal.Title id='confirm-dialog'>
                {title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                {children}
            </Container>
        </Modal.Body>
        <Modal.Footer style={{ width: '100%' }}>
            <div className='row'>
                <div className="col-12">
                    <div className='form-inline justify-content-between'>
                        <div>{showCancel && <button type="button" className="btn bg-gradient-secondary " onClick={() => { setOpenDialog(false) }}>{labelCancel}</button>}
                        </div>
                        <span className={`col-1`}></span>
                        <div>
                            {showSave && <button type="button" className={`btn bg-gradient-primary `} onClick={onSave}>{label}</button>}
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Footer >
    </Modal >

}
