import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import UserContext, { logout } from '../../../../contexts/UserContext';
import axios, { setLogin } from '../../../../my-axios';
import { Button } from 'react-bootstrap';
import './styles.css';

const URL = "/login/logout";

export default function SignOut() {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);

    useEffect(() => {
        console.log("SignOut loading...");
        // console.log("111");
        // console.log(userCtx.state.id);
        // console.log(userCtx.getToken());
        // console.log("222");
        axios.post(URL, {
            id: userCtx.state.id,
            token: userCtx.getToken()
        }) //  /logout
        .then(response => {
            console.log('Logout', response);
            userCtx.logout();
            // navigate("/signin");
        })
        .catch(error => {
            console.log("Logout error", error);
            // navigate("/signin");
        })
    }, []);

    const goHome = () => {
        navigate("/signin");
    };

    return (
        <div className="container">
            <div className="box">
                <h2 className="message">You've been logged out</h2>
                <p className="subMessage">We hope to see you back soon!</p>
                <Button variant="primary" className="button" onClick={goHome}>
                    Back to Home
                </Button>
            </div>
        </div>
    )
}

// const styles = {
//     container: {
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         height: '100vh',
//         background: 'linear-gradient(135deg, #f3f4f6, #e3e6f1)', // Use gradient background
//         padding: '20px'
//     },
//     box: {
//         width: '350px',
//         padding: '30px',
//         backgroundColor: 'white', // white background
//         boxShadow: '0 8px 30px rgba(0, 0, 0, 0.1)', // softer shades
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         alignItems: 'center',
//         borderRadius: '16px', // larger rounded corners
//         textAlign: 'center',
//         animation: 'fadeIn 1s ease-in-out', // Fade in animation effect
//     },
//     message: {
//         marginBottom: '10px',
//         fontSize: '24px', // larger font
//         fontWeight: 'bold',
//         color: '#333', // dark gray font
//     },
//     subMessage: {
//         marginBottom: '20px',
//         fontSize: '16px',
//         color: '#666', // light gray subtitle
//     },
//     button: {
//         width: '180px',
//         fontSize: '16px', // Larger button font
//         borderColor: '#4a90e2'
//     }
// };