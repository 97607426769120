import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { setToken } from '../../../../my-axios';
import UserContext, { getToken } from '../../../../contexts/UserContext';
import ContentContext from '../../../../contexts/ContentContext';
import MsgBox from '../../../controls/MsgBox';
import Spinner from '../../../controls/Spinner';
import { getErrorDesc } from '../../../../utils/common';
import { Button } from 'react-bootstrap';

const URL = '/user/changePassword';
const initialState = {
    loading: false,
    success: false,
    msg: null,
    oldPwd: null,
    newPwd: null,
    newPwdConfirm: null
};

export default function ChangePassword() {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const contentCtx = useContext(ContentContext);
    const [state, setState] = useState(initialState);

    useEffect(() => {
        contentCtx.updatePath([
            {
                title: 'Change Password',
                link: '/change-password'
            }]);
    }, []);

    const changeField = (field, value) => {
        console.log('changeField', field, value, '->', state[field]);
        setState({ ...state, [field]: value, success: false, msg: null, error: null });
    }

    const changePassword = () => {
        console.log("changePassword", state);
        if (!state.oldPwd) {
            setState({ ...state, msg: 'Please input the current password!' });
            return;
        }
        if (!state.newPwd) {
            setState({ ...state, msg: 'Please input a new password!' });
            return;
        }
        if (!state.newPwdConfirm) {
            setState({ ...state, msg: 'Please confirm the new password!' });
            return;
        }
        if (state.newPwd !== state.newPwdConfirm) {
            setState({ ...state, msg: 'Mismatching new password confirmation!' });
            return;
        }
        if (state.newPwd.length < 6) {
            setState({ ...state, msg: 'The new password must be longer than five characters!' });
            return;
        }

        var data = {
            oldPassword: state.oldPwd,
            newPassword: state.newPwd
        };

        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        axios.put

            (URL, data).then((result) => {
                console.log(URL + ": ", result.data);
                setState({ ...state, oldPwd: null, newPwd: null, newPwdConfirm: null, loading: false, success: true, msg: 'Your password successfully changed!' });
            }).catch((error) => {
                console.log('activateuser error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state, oldPwd: null, newPwd: null, newPwdConfirm: null,
                        loading: false, success: false, msg: getErrorDesc(error)
                    });
                }
            });

    };

    return (
        <div style={{ width: '800px' }}>
            {state.success && <MsgBox value={{ type: 'success', title: '', msg: state.msg }} />}

            {!state.success && state.msg && <MsgBox value={{ type: 'error', title: '', msg: state.msg }} />}

            {!state.success &&
                <div style={{ width: '200px' }}>
                    <div className="input-group mb-3">
                        <input type="password" className="form-control" placeholder="Current Password" name='oldPwd' value={state.oldPwd} onChange={(e) => changeField('oldPwd', e.target.value)} />
                    </div>
                    <div className="input-group mb-3">
                        <input type="password" className="form-control" placeholder="New Password" name='newPwd' value={state.newPwd} onChange={(e) => changeField('newPwd', e.target.value)} />
                    </div>
                    <div className="input-group mb-3">
                        <input type="password" className="form-control" placeholder="Confirm New Password" name='newPwdConfirm' value={state.newPwdConfirm} onChange={(e) => changeField('newPwdConfirm', e.target.value)} />
                    </div>
                    {state.success !== true && <div className="input-group mb-3 text-right">
                        <Button onClick={changePassword}>Save</Button>
                    </div>}
                </div>
            }
        </div >
    )
}
