import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext, { getToken } from '../../../contexts/UserContext';
import ContentContext from '../../../contexts/ContentContext';
import axios from '../../../my-axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import PopupPage from '../../controls/PopupPage';
import { getErrorDesc, date2str, dateTime2str, getDate, getFullName } from '../../../utils/common';

const URL = "/appointment";
const initState = {
    data: [],
    selectedRow: null,
    loading: false,
    msg: null
};

const statusList = [
    {
        code: "ALL",
        name: 'All'
    },
    {
        code: 'CONFIRMED',
        name: 'CONFIRMED'
    },
    {
        code: "UNCONFIRMED",
        name: 'UNCONFIRMED'
    }
];

const initPaginationState = {
    total: 0,
    page: 1,
    size: 50
};

// Dummy data for events
const events = [
    {
        title: 'All Day Event',
        start: '2024-05-01',
        backgroundColor: '#f56954', // red
        borderColor: '#f56954', // red
        allDay: true,
    },
    // Add more events as needed
];

export default function Calendar() {
    const calendarRef = useRef(null);
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const contentCtx = useContext(ContentContext);

    const [state, setState] = useState(initState);
    const [currentPage, setCurrentPage] = useState(initPaginationState);
    const [filter, setFilter] = useState({ selectedStatus: statusList[0], date: getDate() });
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    useEffect(() => {
        contentCtx.updatePath([{ title: 'Calendar', link: '/calendar' }]);
        const handleResize = () => {
            setScreenHeight(window.innerHeight);
        };

        // Listen for window resize events
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener when component unmounts

        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            const firstDate = calendarApi.view.currentStart;
            calendarApi.on('eventClick', handleEventClick);
            calendarApi.setOption('views', {
                timeGridDay: {
                    allDaySlot: false
                }
            });
            // calendarApi.setOption('slotDuration', '00:20:00'); // Set slot duration to 20 minutes
            calendarApi.setOption('slotMinTime', '07:00:00'); // Set min time for slots
            calendarApi.setOption('slotMaxTime', '20:00:00');
            calendarApi.setOption('slotDuration', '00:30:00');
            //console.log('First date of the calendar:', firstDate);
            setFilter({ ...filter, date: firstDate });
            // Load data with the first date
            loadData({ ...filter, date: firstDate });
        }
        else {
            console.log("No ref");
        }
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const loadData = (filterValues) => {
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        axios.get(URL,
            {
                params: {
                    page: currentPage.page,
                    size: currentPage.size,
                    status: filterValues.selectedStatus.code,
                    text: filterValues.text,
                    date: filterValues.date
                },
            }).then((result) => {
                console.log("GetFromServer!!!", result.data);
                setState({
                    ...state, data: formatData(result.data.data), loading: false, msg: null
                });
                setCurrentPage({ ...currentPage, total: result.data.total });
            }).catch((error) => {
                console.log('error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state, data: [], loading: false, msg: getErrorDesc(error)
                    });
                }
            });
    };

    const formatData = (data) => {
        return data.map((r) => {
            return {
                ...r,
                //status: getStatus(r.status).name,
                start: dateTime2str(r.date),
                allDay: false,
                title: getTitle(r),
                backgroundColor: r.status == 'UNCONFIRMED' ? '#f56954' : '#56C21C', // red
                borderColor: r.status == 'UNCONFIRMED' ? '#f56954' : '#56C21C', // red
            };
        });
    };

    const getTitle = (row) => {
        var title = getFullName(row);
        return title + " " + row.detail;
    }

    const handleEventClick = (clickInfo) => {
        // Access the event object clicked
        const event = clickInfo.event;
        //console.log('Event clicked:', event);
        navigate("/appointments/" + event.id);
    };

    return (
        <div className="card-body pt-0">
            <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin]}
                initialView="dayGridMonth"
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                themeSystem="bootstrap"
                events={state.data}
                editable={false}
                height={`${screenHeight - 100}px`}
            // handleWindowResize={true}
            // droppable={true}
            // eventDrop={handleEventDrop}
            />
            {/* The Calendar */}

        </div>
    )
}

// // Function to handle event drop (optional)
// const handleEventDrop = (info) => {
//     // Your event drop logic here
// };