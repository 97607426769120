import React, { useContext, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import ContentContext from '../../../contexts/ContentContext';

const regex = /^\d+$/;

export default function Content(props) {

    const contentCtx = useContext(ContentContext);
    //console.log('contentCtx.path', contentCtx.path);
    //const location = useLocation();
    // // Splitting the pathname to get the last segment
    // const pathSegments = location.pathname.split('/');

    // const getTitle = () => {
    //     let i = 1;
    //     while (i < pathSegments.length) {
    //         var lastSegment = pathSegments[pathSegments.length - i++];
    //         var str = lastSegment === null ? "" : lastSegment.toLowerCase();
    //         // Check if the text contains only digits
    //         var containsOnlyDigits = regex.test(str);
    //         if (!containsOnlyDigits) {
    //             switch (str) {
    //                 case "patients":
    //                     return "Patients";
    //                 case "appointments":
    //                     return "Appointments";
    //                 case "contacts":
    //                     return "Contacts";
    //                 case "users":
    //                     return "Users";
    //                 case "settings":
    //                     return "Settings";
    //                 default:
    //                     return "Calendar";
    //             }
    //         }
    //     }
    //     return 'Calendar';
    // }

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            {contentCtx.title !== 'Calendar'
                ? <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{contentCtx.title}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item active">{contentCtx.path.map((p, index) => {
                                        let link1 = p.link;
                                        let label1 = p.title;
                                        if (index > 0) label1 = '/ ' + label1;

                                        return (
                                            <React.Fragment key={p.title}>
                                                <Link to={link1}>
                                                    {label1} </Link>
                                            </React.Fragment>)
                                    })}</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section >
                : <div style={{ height: '5px' }}></div>
            }
            {/* Main content */}
            < section className="content" >
                <div className="container-fluid">
                    {/* /.row */}
                    <div className="card p-10"
                        style={{
                            padding: '20px'
                        }}>
                        {props.children}
                    </div>
                    {/* </div > */}
                </div >
                {/* /.container-fluid */}
            </section >
            {/* /.content */}
        </div >


    )
}
