import React, { useEffect, useContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import PatientDetail from './PatientDetail'
import PatientList from './PatientList'



export default function Patients(props) {
    return (
        <Routes>
            <Route path="/:id" element={<PatientDetail />} />
            <Route path="/" element={<PatientList />} />
        </Routes>
    )
}
