import React, { useEffect, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import ResourceDetail from './ResourceDetail';
import PatientList from './PatientList';
import ResourceList from './ResourceList';



export default function Patients (props) {
    return (
        <Routes>
            <Route path="/patient/:id" element={<ResourceList />} />
            <Route path="/" element={<PatientList />} />
            <Route path="/:id" element={<ResourceDetail />} />
            <Route path="/new/:pid" element={<ResourceDetail />} />
        </Routes>
    );
}
