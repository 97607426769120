import React from 'react'
import { Routes, Route } from 'react-router-dom'
import DoctorDetail from './DoctorDetail'
import DoctorList from './DoctorList'

export default function Doctors() {
    return (
        <Routes>
            <Route path="/:id" element={<DoctorDetail />} />
            <Route path="/new" element={<DoctorDetail />} />
            <Route path="/" element={<DoctorList />} />
        </Routes>
    )
}
