import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import UserContext, { login, getUser } from '../../../../contexts/UserContext';
import axios, { setToken, toAbsoluteUrl } from '../../../../my-axios'
import MsgBox from '../../../controls/MsgBox';
import './styles.css';

const URL = "/login/webapp";
const local_storage_remember_key = 'medsec.remember';
const local_storage_email_key = 'medsec.email';
const session_storage_token_key = 'token';
const error_timeout = 8000;

const getEmail = () => {
    var storedEmail = localStorage.getItem(local_storage_email_key);
    if (storedEmail)
        return storedEmail;
    else
        return '';
}
const getRemember = () => {
    var storedRemember = localStorage.getItem(local_storage_remember_key);
    if (storedRemember)
        return storedRemember == 'true';
    else
        return true;
}

export default function SignIn() {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const [email, setEmail] = useState(getEmail());
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(getRemember());
    const [error, setError] = useState('');
    const [captcha, setCaptcha] = useState();
    const [showCaptcha, setShowCaptcha] = useState(false);

    useEffect(() => {
        if (userCtx.getToken() && userCtx.getUser()) {
            console.log("SignIn logged...");
            navigate("/");
        }

        console.log('SignIn', remember, email);
    }, [])

    const onChangeEmail = (event) => {
        setEmail(event.target.value);
    }

    const onChangePassword = (event) => {
        setPassword(event.target.value);
    }

    const onChangeRemember = (event) => {
        console.log('onChangeRemember', event.target.checked);
        setRemember(current => !current);
    }

    const validation = () => {
        if (email === '' && password === '') {
            setError('Please input your email and password!');
            setTimeout(() => {
                setError(null);
            }, error_timeout);
            return false;
        }
        if (email === '') {
            setError('Please input your email!');
            setTimeout(() => {
                setError(null);
            }, error_timeout);
            return false;
        }
        if (password === '') {
            setError('Please input your password!');
            setTimeout(() => {
                setError(null);
            }, error_timeout);
            return false;
        }
        return true;
    }

    const login = (event) => {
        event.preventDefault();

        if (showCaptcha && !captcha) {
            return;
        }

        setError('');
        //console.log('Login', email, password, remember)
        if (!validation()) {
            return;
        }
        const data = {
            "email": email,
            "password": password,
            "captcha": captcha
        };
        axios.post(URL, data).then((result) => {
            let u = result.data;
            console.log('login result: ', u);
            var user = { loggedIn: true, email, id: u.id, surname: u.surname, firstname: u.firstname, role: u.role, clid: u.cid };
            userCtx.login(user, result.data.token);
            setToken(result.data.token);
            //userCtx.autoLogout(1000 * 60 * 60 * 24); // 1d  TODO

            // To store the value of the "remember me" checkbox
            localStorage.setItem(local_storage_remember_key, remember);
            if (remember === true) {
                // To store the email address when remember me is checked
                localStorage.setItem(local_storage_email_key, user.email);
            } else {
                // To remove the email address when remember me is unchecked
                localStorage.removeItem(local_storage_email_key);
            }
            navigate('/');

        }).catch((err) => {
            setCaptcha(null);
            console.log('Login.error: ', err)
            //setLoading(false);
            if (err.response && err.response.data)
                setError(`${err.response.status} ${err.response.data}`);
            else if (err.response)
                setError(`${err.response.status} ${err.response.code}`);
            else
                setError(err.code);
            if (err.response && err.response.status === 429) {
                // show captcha
                console.log('Show captcha');
                if (showCaptcha)
                    window.grecaptcha.reset();
                setShowCaptcha(true);
            }

            var err = { loading: false, error: err.message, errorCode: err.code, token: null, userId: null, email: null, image: null }
            //userCtx.login(err);
        });
    }

    const onChangeCaptcha = (value) => {
        setCaptcha(value);
    }

    const onExpiredCaptcha = () => {
    }

    return (
        <div className='d-flex flex-row-fluid login-page position-relative p-7 overflow-hidden col-lg-8'>
            <div className='d-flex flex-column-fluid flex-center mt-30 mt-lg-0'>
                <div className="hold-transition ">
                    <div className="login-box">
                        {/* /.login-logo */}
                        {/* style={{ backgroundColor: '#07477A' }} */}
                        <div className="card card-outline card-primary" >
                            <div className="card-header text-center" >
                                <h1>Sign In</h1>
                            </div>
                            <div className="card-body">
                                <p className="login-box-msg">Enter your email and password</p>
                                <form onSubmit={login}>
                                    <div className="input-group mb-3">
                                        <input type="email" className="form-control" placeholder="Email" name='email' value={email} onChange={onChangeEmail} />
                                        < div className="input-group-append" >
                                            <div className="input-group-text fixed-width-icon" >
                                                <span className="fas fa-envelope" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input type="password" className="form-control" placeholder="Password" onChange={onChangePassword} />
                                        <div className="input-group-append">
                                            <div className="input-group-text fixed-width-icon">
                                                <span className="fas fa-lock" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-8">
                                            <div className="icheck-primary">
                                                <input type="checkbox" id="remember" defaultChecked value={remember} onChange={onChangeRemember} />
                                                <label htmlFor="remember" style={{ fontWeight: 'normal', marginLeft: '5px' }}>
                                                    <span> </span>Remember Me
                                                </label>
                                            </div>
                                        </div>
                                        {/* /.col */}
                                        <div className="col-4">
                                            <button type="submit" className="btn btn-primary btn-block">Login</button>
                                        </div>
                                        {/* /.col */}
                                    </div>
                                </form>
                                {/* /.social-auth-links */}
                                <p className="mb-1">
                                    <Link to="/signup">Sign up</Link>
                                </p>
                                <p className="mb-1">
                                    <Link to="/forgot-password">I forgot my password</Link>
                                </p>
                                {showCaptcha && <ReCAPTCHA
                                    sitekey="6LdQk-ApAAAAALBevXlhr-_axa64gkUa-3D5vHYF"
                                    onChange={onChangeCaptcha}
                                    onExpired={onExpiredCaptcha}
                                />}
                                {/* {showCaptcha && <div className="g-recaptcha" data-sitekey="6LcvKOApAAAAAP2UmoIUJk-Z3kgFnj9UFcfGzVT0" data-action="LOGIN"></div>} */}
                                {/* <Spinner value={userCtx.state.loading} /> */}
                                <div className="row">
                                    <div className="col-12">
                                        <MsgBox value={{ type: 'error', title: '', msg: error }} />
                                    </div>
                                </div>
                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div >
                    {/* /.login-box */}
                </div >
            </div>
        </div>
    )
}
