import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext, { getToken } from '../../../../contexts/UserContext';
import ContentContext from '../../../../contexts/ContentContext';

export default function ContactList() {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const contentCtx = useContext(ContentContext);

    useEffect(() => {
        contentCtx.updatePath([{ title: 'Doctors | Hospitals | Radiologies | Pathologies | Specialists', link: '/contacts' }]);
    }, []);
    return (
        <div>Coming soon..

        </div>
    )
}
