import React from 'react'
import { Alert } from 'react-bootstrap';

export default function MsgBox(props) {
    const { type, title, msg } = props.value;
    const getVariant = (variant) => {
        switch (variant) {
            case 'error':
                return 'danger';
            case 'success':
                return 'success';
            case 'warning':
                return 'warning'
            case 'info':
            default:
                return 'info';
        }
    }
    return (
        < >
            {msg !== null && msg !== '' && <Alert variant={getVariant(type)} className="fade show">
                <Alert.Heading>{title}</Alert.Heading>
                <p>
                    {msg}
                </p>
            </Alert>}
        </>
    )
}
