import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { setToken } from '../../../../my-axios';
import UserContext, { getToken } from '../../../../contexts/UserContext';
import ContentContext from '../../../../contexts/ContentContext';
import MsgBox from '../../../controls/MsgBox';
import Spinner from '../../../controls/Spinner';
import { getErrorDesc } from '../../../../utils/common';
import { Button } from 'react-bootstrap';

const URL = '/login/resetPassword';

export default function ForgetPassword() {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const contentCtx = useContext(ContentContext);
    const [email, setEmail] = useState();
    const [error, setError] = useState();
    const [msg, setMsg] = useState();

    const changeEmail = (val) => {
        setEmail(val);
    }

    const resetPassword = () => {
        setError(null);
        setMsg(null);
        if (!email) {
            setError('Please input your email address!');
            return;
        }

        var data = { email };
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        axios.post(URL, data).then((result) => {
            console.log(URL + ": ", result.data.message);
            setMsg(result.data.message);
        }).catch((error) => {
            console.log('reset password error:', error);
            setMsg(null);
            if (error.response && error.response.status == 401) {
                navigate("/signout");
            }
            else {
                setError(getErrorDesc(error));
            }
        });

    };

    const cancel = () => {
        navigate('/signin');
    }

    return (
        <div className='d-flex flex-row-fluid login-page position-relative p-7 overflow-hidden col-lg-8'>
            <div className='d-flex flex-column-fluid flex-center mt-30 mt-lg-0'>
                <div className="hold-transition ">
                    <div className="login-box">
                        {/* /.login-logo */}
                        {/* style={{ backgroundColor: '#07477A' }} */}
                        <div className="card card-outline card-primary" >
                            <div className="card-header text-center" >
                                <h2>Reset password</h2>
                            </div>
                            <div className="card-body">
                                {error && <MsgBox value={{ type: 'error', title: '', msg: error }} />}
                                {msg ?
                                    <MsgBox value={{ type: 'success', title: '', msg }} />
                                    :
                                    <>
                                        <p className="login-box-msg">Enter your email address</p>
                                        <div className="input-group mb-3">
                                            <input type="email" className="form-control" placeholder="Email" name='email' value={email} onChange={(e) => changeEmail(e.target.value)} />
                                        </div>
                                    </>
                                }
                                <div className="row ">
                                    {/* /.col */}
                                    <div className="col text-right">
                                        {msg ?
                                            <Button variant='primary' onClick={cancel} className='mr-2'>Go to Sign In</Button>
                                            :
                                            <>
                                                <Button variant='secondary' onClick={cancel} className='mr-2'>Cancel</Button>
                                                <Button variant='primary' onClick={resetPassword}>Reset Password</Button>
                                            </>
                                        }
                                    </div>
                                    {/* /.col */}
                                </div>

                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div >
                    {/* /.login-box */}
                </div >
            </div>
        </div >
    )
}
