import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from './logo.svg';
import UserContext from '../../../contexts/UserContext';

export default function Menu() {
    const userCtx = useContext(UserContext);
    const [user, setUser] = useState();

    useEffect(() => {
        setUser(userCtx.getUser());
    }, []);

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4"
            style={{ backgroundColor: '#07477A' }}>
            {/* Brand Logo */}
            < Link className='brand-link' to='/' >
                <img src={logo} alt="MMS Logo" className="brand-image img-circle elevation-3" style={{ opacity: '1' }} />
                {/* <i className="nav-icon fas fa-plus" /> */}
                {/* <i className="brand-image img-circle fas fa-plus" style={{ opacity: '.8' }} /> */}
                {/* <i className="nav-icon fas fa-plus" style={{ opacity: '.8' }} /> */}
                <span className="brand-text font-weight-light"> Medical Secretary</span>
            </Link >
            {/* Sidebar */}
            < div className="sidebar" >
                {/* Sidebar user panel (optional) */}
                {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
                    </div>
                    <div className="info">
                        <a href="#" className="d-block">Alexander Pierce</a>
                    </div>
                </div> */}
                {/* SidebarSearch Form */}
                {/* <div className="form-inline">
                    <div className="input-group" data-widget="sidebar-search">
                        <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                        <div className="input-group-append">
                            <button className="btn btn-sidebar">
                                <i className="fas fa-search fa-fw" />
                            </button>
                        </div>
                    </div>
                </div> */}
                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}

         {user && user.role !== "ROOT" &&
         <>
                        <li className="nav-item">
                            <Link className='nav-link' to='/patients'>
                                <i className="nav-icon fas fa-address-card" />
                                <p>Patients</p>
                                {/* <span className="right badge badge-danger">New</span> */}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className='nav-link' to='/appointments'>
                                <i className="nav-icon fas fa-calendar-alt" />
                                <p>
                                    Appointments
                                    {/* <span className="badge badge-info right">2</span> */}
                                </p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className='nav-link' to='/importGenieReports'>
                                <i className="nav-icon fa fa-upload" />
                                <p>
                                    Import Reports
                                </p>
                            </Link>
                        </li>
                        <div className="dropdown-divider" />
                        <li className="nav-item">
                            <Link className='nav-link' to='/resources'>
                                <i className="nav-icon 	fas fa-file-pdf" />
                                <p>
                                    Resources
                                    {/* <i className="fas fa-angle-left right" /> */}
                                    {/* <span className="badge badge-info right">6</span> */}
                                </p>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className='nav-link' to='/doctors'>
                                <i className="nav-icon 	fas fa-user-md" />
                                <p>
                                    Doctors
                                    {/* <i className="fas fa-angle-left right" /> */}
                                    {/* <span className="badge badge-info right">6</span> */}
                                </p>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className='nav-link' to='/hospitals'>
                                <i className="nav-icon 	fas fa-clinic-medical" />
                                <p>
                                    Hospitals
                                    {/* <i className="fas fa-angle-left right" /> */}
                                    {/* <span className="badge badge-info right">6</span> */}
                                </p>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className='nav-link' to='/pathologies'>
                                <i className="nav-icon fas fa-stethoscope" />
                                <p>
                                    Pathologies
                                    {/* <i className="fas fa-angle-left right" /> */}
                                    {/* <span className="badge badge-info right">6</span> */}
                                </p>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className='nav-link' to='/radiologies'>
                                <i className="nav-icon fas fa-radiation" />
                                <p>
                                    Radiologies
                                    {/* <i className="fas fa-angle-left right" /> */}
                                    {/* <span className="badge badge-info right">6</span> */}
                                </p>
                            </Link>
                        </li>
                    </>
                    }
                    {user && user.role === "ROOT" &&
                                <li className="nav-item">
                                    <Link className='nav-link' to='/clinics'>
                                        <i className="nav-icon 	fas fa-clinic-medical" />
                                        <p>
                                            Clinics
                                            {/* <i className="fas fa-angle-left right" /> */}
                                            {/* <span className="badge badge-info right">6</span> */}
                                        </p>
                                    </Link>
                                </li>
                    }
                        <div className="dropdown-divider" />
                        {/* Admin Role's Menu */}
                        {user && (user.role === 'ADMIN' || user.role === "ROOT") &&

                            <li className="nav-item">
                                <Link className='nav-link' to='/users'>
                                    <i className="nav-icon fa fa-user" />
                                    <p>
                                        Users
                                    </p>
                                </Link>
                            </li>
                        }
                    </ul>
                </nav>
                {/* /.sidebar-menu */}
            </div >
            {/* /.sidebar */}
        </aside >

    );
}
