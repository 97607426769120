import React, { useEffect, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import AppointmentsList from './AppointmentsList';
import AppointmentDetail from './AppointmentDetail';

export default function Appointments() {
    return (
        <Routes>
            <Route path="/:id" element={<AppointmentDetail />} />
            <Route path="/" element={<AppointmentsList />} />
        </Routes>
    );
}
