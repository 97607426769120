import React, { useState, useContext, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from '../../../../my-axios';
import UserContext from '../../../../contexts/UserContext';
import ContentContext from '../../../../contexts/ContentContext';
import { Table, Button } from 'react-bootstrap';
import MsgBox from '../../../controls/MsgBox';
import Spinner from '../../../controls/Spinner';
import PopupPage from '../../../controls/PopupPage';
import { getErrorDesc } from '../../../../utils/common';

const URL = '/users';
const initialState = {
    msg: null,
    loading: false,
    changing: false,
};

const initialClinic = {
    name: '',
    email: '',
    phone: '',
    street: '',
    suburb: '',
    state: ''
}

export default function ClinicDetail() {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const contentCtx = useContext(ContentContext);

    const { id } = useParams();
    const [state, setState] = useState(initialState);
    const [clinic, setClinic] = useState(initialClinic);

    useEffect(() => {
        contentCtx.updatePath([
            {
                title: 'Clinics',
                link: '/clinics'
            },
            {
                title: 'Clinic Detail',
                link: `/clinics/${id}`
            }]);
        if (id)
            loadClinicData(id);
    }, [id]);

    const loadClinicData = (id) => {
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        axios.get(`${URL}/${id}`).then((result) => {
            console.log("GetFromServer", result.data);
            setClinic({ ...result.data });
            setState({ ...state, loading: false, msg: null });
        }).catch((error) => {
            console.log('error:', error);
            if (error.response && error.response.status === 401) {
                navigate("/signout");
            } else {
                setState({
                    ...state,
                    loading: false, msg: getErrorDesc(error)
                });
            }
        })
    }

    return (
        <div>
            <div className="card-body">
                <Spinner value={state.loading} />
                <MsgBox value={{ type: 'error', title: '', msg: state.msg }} />
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Clinic Name</th>
                            <th>Clinic Email</th>
                            <th>Phone</th>
                            <th>Street</th>
                            <th>Suburb</th>
                            <th>State</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{clinic.name}</td>
                            <td>{clinic.email}</td>
                            <td>{clinic.phone}</td>
                            <td>{clinic.street}</td>
                            <td>{clinic.suburb}</td>
                            <td>{clinic.state}</td>
                        </tr>
                    </tbody>
                </Table>
                <div className="row">
                    <div className="col text-right">
                        <Button style={{ width: '100px' }} onClick={() => navigate('/clinics')}> Back</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
