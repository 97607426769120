import React from 'react';
import css from './style.module.css';

export default function Spinner({ value }) {
    return (
        <div>
            {value && <div className={css.loader}>Loading...</div>}
        </div>
    )
}
