import React, { useEffect, useState } from 'react';

const UserContext = React.createContext();

export const initialState = {
    loading: false,
    loggedIn: false,
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    token: null,
    error: null
};
export const local_storage_user_key = 'medsec.user';
export const session_storage_token_key = 'token';

const URL = '/login';

export const UserStore = (props) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        // console.log('LoadUserContext');
        let tmp = JSON.parse(localStorage.getItem(local_storage_user_key));
        //console.log('LoadUserContext.from Storage', tmp);
        if (tmp) {
            //console.log('LoadUserContext.refreshState', tmp);
            setState(tmp);
        }
    }, []);

    const login = (user, token) => {
        setState({
            ...user, loading: false, error: null, loggedIn: true,
            email: user.email
        });

        localStorage.setItem(session_storage_token_key, token);
        localStorage.setItem(local_storage_user_key, JSON.stringify(user));
    }

    const logout = () => {
        localStorage.removeItem(local_storage_user_key);
        localStorage.removeItem(session_storage_token_key);
        setState(initialState);
    }

    const autoLogout = (ms) => {
        setTimeout(() => {
            // TODO check token expire time 
            //console.log('AutoLogout after ', ms, ' millseconds')
            localStorage.removeItem(local_storage_user_key);
            setState(initialState);
        }, ms)
    }

    const getToken = () => {
        return localStorage.getItem(session_storage_token_key);
    }

    const getUser = () => {
        var user = null;
        var userStr = localStorage.getItem(local_storage_user_key);
        //console.log('LoadLoginState:', userStr);

        if (userStr && userStr !== '') {
            user = JSON.parse(userStr);
        }
        if (user && user.loggedIn) {
            setState(user)
            //console.log('MainPage.setTokentoAxios', user.token);
            return user;
        }
        else
            return null;
    }

    return (
        <UserContext.Provider value={{ state, login, logout, autoLogout, getToken, getUser }}>
            {props.children}
        </UserContext.Provider>
    );
};


export default UserContext;