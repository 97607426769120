import React, { useState } from 'react';

const ContentContext = React.createContext();
const home = { title: 'Home', link: '/' }

export const ContentStore = (props) => {
    const [title, setTitle] = useState();
    const [path, setPath] = useState([home]);

    const resetPath = (title, link) => {
        setPath([home, { title, link }]);
        setTitle(title);
    }

    const updatePath = (paths) => {
        let tmp = [home];
        let tit = 'Calendar';
        if (paths) {
            paths.map((p, index) => {
                tmp.push({ title: p.title, link: p.link });
                if (index === paths.length - 1)
                    tit = p.title;
            })
        }
        setPath(tmp);
        setTitle(tit);
    }

    return (
        <ContentContext.Provider value={{ title, path, updatePath }}>
            {props.children}
        </ContentContext.Provider>
    );
};


export default ContentContext;