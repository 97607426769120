import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import UserContext from '../../../../contexts/UserContext';
import axios from '../../../../my-axios';
import { getErrorDesc } from '../../../../utils/common';
import MsgBox from '../../../controls/MsgBox';
import { Button } from 'react-bootstrap';

const URL = '/login/resetPassword';
export default function ResetPassword() {
    const { token } = useParams();
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();

    useEffect(() => {
        console.log("ResetPassword", token);
        if (token)
            sendToken();
    }, []);

    const sendToken = () => {
        setError(null);
        setMsg(null);
        if (!token) {
            setError('Empty Token!');
            return;
        }

        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        axios.get(`${URL}/${token}`).then((result) => {
            console.log(URL + ": ", result.data.message);
            setMsg(result.data.message);
        }).catch((error) => {
            console.log('reset password error:', error);
            setMsg(null);
            setError(getErrorDesc(error));
        });
    }

    const cancel = () => {
        navigate('/signin');
    }

    return (
        <div className='d-flex flex-row-fluid login-page position-relative p-7 overflow-hidden col-lg-8'>
            <div className='d-flex flex-column-fluid flex-center mt-30 mt-lg-0'>
                <div className="hold-transition ">
                    <div className="login-box">
                        {/* /.login-logo */}
                        {/* style={{ backgroundColor: '#07477A' }} */}
                        <div className="card card-outline card-primary" >
                            <div className="card-header text-center" >
                                <h2>Reset password</h2>
                            </div>
                            <div className="card-body">
                                {error
                                    && <MsgBox value={{ type: 'error', title: '', msg: error }} />}
                                {msg
                                    && <MsgBox value={{ type: 'success', title: '', msg }} />
                                }
                                <div className="row ">
                                    {/* /.col */}
                                    <div className="col text-right">
                                        {msg &&
                                            <Button variant='primary' className='mr-2' onClick={cancel}>Go to Sign In</Button>
                                        }
                                    </div>
                                    {/* /.col */}
                                </div>

                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div >
                    {/* /.login-box */}
                </div >
            </div>
        </div >
    )
}
