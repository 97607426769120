import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../my-axios'; // Import axios for making HTTP requests
import UserContext from '../../../../contexts/UserContext'; // Import user context for token handling
import MsgBox from '../../../controls/MsgBox'; // Message box component for displaying error/success messages
import { Button } from 'react-bootstrap'; // Import Bootstrap Button for styling

const URL = '/register/clinic'; // API endpoint for clinic sign-up

export default function SignUp() {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext); // Get user context
    const [clinicName, setClinicName] = useState(''); // State for clinic name input
    const [clinicEmail, setClinicEmail] = useState(''); // State for clinic email input
    const [phone, setPhone] = useState(''); // State for phone input
    const [street, setStreet] = useState(''); // State for street input
    const [suburb, setSuburb] = useState(''); // State for suburb input
    const [state, setState] = useState(''); // State for state input
    const [status, setStatus] = useState(''); // State for status input
    const [password, setPassword] = useState(''); // State for password input
    const [firstname, setFirstname] = useState(''); // State for first name input
    const [lastname, setLastname] = useState(''); // State for last name input
    const [error, setError] = useState(); // State for error messages
    const [msg, setMsg] = useState(); // State for success messages

    // Handle input changes for each field
    const changeClinicName = (val) => setClinicName(val);
    const changeClinicEmail = (val) => setClinicEmail(val);
    const changePhone = (val) => setPhone(val);
    const changeStreet = (val) => setStreet(val);
    const changeSuburb = (val) => setSuburb(val);
    const changeState = (val) => setState(val);
    const changeStatus = (val) => setStatus(val);
    const changePassword = (val) => setPassword(val); 
    const changeFirstname = (val) => setFirstname(val); // Handle first name input
    const changeLastname = (val) => setLastname(val); // Handle last name input

    // Function to submit the sign-up form
    const signUp = () => {
        setError(null); // Reset error state
        setMsg(null); // Reset success message state
        if (!clinicName || !clinicEmail || !phone || !street || !suburb || !state || !password || !firstname || !lastname) {
            setError('Please fill in all fields!'); // Validate required fields
            return;
        }

        // Prepare the data for submission
        const data = { clinicName, clinicEmail, phone, street, suburb, state, status, password, firstname, lastname };

        // Send the sign-up request
        axios.post(URL, data).then((result) => {
            console.log(URL + ": ", result.data.message);
            setMsg(result.data.message); // Display success message
        }).catch((error) => {
            console.log('sign up error:', error);
            setError('Failed to register clinic. Please try again.'); // Handle error
        });
    };

    // Function to cancel the sign-up process and navigate back to sign-in
    const cancel = () => {
        navigate('/signin');
    }

    return (
        <div className='d-flex flex-row-fluid login-page position-relative p-7 overflow-hidden col-lg-8'>
            <div className='d-flex flex-column-fluid flex-center mt-30 mt-lg-0'>
                <div className="hold-transition">
                    <div className="login-box" style={{ overflowY: 'auto', maxHeight: '100vh', width: '150%', marginLeft: '-50px' }}>
                        {/* Card container */}
                        <div className="card card-outline card-primary">
                            <div className="card-header text-center">
                                <h2>Sign Up</h2> {/* Title for sign-up */}
                            </div>
                            <div className="card-body">
                                {/* Display error message if exists */}
                                {error && <MsgBox value={{ type: 'error', title: '', msg: error }} />}
                                {/* Display success message if exists */}
                                {msg ? (
                                    <MsgBox value={{ type: 'success', title: '', msg }} />
                                ) : (
                                    <>
                                        {/* Sign-up form */}
                                        <p className="login-box-msg">Fill in the form to create clinic account</p>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Clinic Name" value={clinicName} onChange={(e) => changeClinicName(e.target.value)} />
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="email" className="form-control" placeholder="Clinic Email" value={clinicEmail} onChange={(e) => changeClinicEmail(e.target.value)} />
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Your First Name" value={firstname} onChange={(e) => changeFirstname(e.target.value)} />
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Your Last Name" value={lastname} onChange={(e) => changeLastname(e.target.value)} />
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => changePassword(e.target.value)} />
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="tel" className="form-control" placeholder="Phone" value={phone} onChange={(e) => changePhone(e.target.value)} />
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Street" value={street} onChange={(e) => changeStreet(e.target.value)} />
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Suburb" value={suburb} onChange={(e) => changeSuburb(e.target.value)} />
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="State" value={state} onChange={(e) => changeState(e.target.value)} />
                                        </div>
                                    </>
                                )}
                                {/* Sign-up and cancel buttons */}
                                <div className="row">
                                    <div className="col text-right">
                                        {msg ? (
                                            <Button variant='primary' onClick={cancel} className='mr-2'>Go to Sign In</Button>
                                        ) : (
                                            <>
                                                <Button variant='secondary' onClick={cancel} className='mr-2'>Cancel</Button>
                                                <Button variant='primary' onClick={signUp}>Sign Up</Button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>
                    {/* /.login-box */}
                </div>
            </div>
        </div>
    )
}

