import React from 'react'
import { Routes, Route } from 'react-router-dom'
import UserDetail from './UserDetail'
import UserList from './UserList'

export default function Users() {
    return (
        <Routes>
            <Route path="/:id" element={<UserDetail />} />
            <Route path="/new" element={<UserDetail />} />
            <Route path="/" element={<UserList />} />
        </Routes>
    )
}
