import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { setToken } from '../../../../my-axios';
import UserContext, { getToken } from '../../../../contexts/UserContext';
import ContentContext from '../../../../contexts/ContentContext';
import DataTable from '../../../controls/DataTable';
import DataTablePagination from '../../../controls/DataTablePagination';
import SearchBox from '../../../controls/SearchBox';
import { Dropdown, Button } from 'react-bootstrap';
import MsgBox from '../../../controls/MsgBox';
import Spinner from '../../../controls/Spinner';
import { getErrorDesc, date2str } from '../../../../utils/common';


const URL = "/patients";
const titles = ["ID", "SURNAME", "FIRSTNAME", "EMAIL", "DATE OF BIRTH", "STATUS"];
const cols = ["id", "surname", "firstname", "email", "dob", "status"];

const statusList = [
    {
        code: -1,
        name: 'ALL'
    },
    {
        code: 1,
        name: 'ACTIVE'
    },
    {
        code: 0,
        name: 'NEW'
    },
    {
        code: 2,
        name: 'INACTIVE'
    }
];

const initState = {
    data: [],
    selectedRow: null,
    loading: false,
    msg: null
};

const initPaginationState = {
    total: 0,
    page: 1,
    size: 10
};


const initFilterState = {
    text: '',
    selectedStatus: statusList[1]
};


export default function PatientList() {
    //console.log('PatientList')
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const contentCtx = useContext(ContentContext);
    const [state, setState] = useState(initState);
    const [currentPage, setCurrentPage] = useState(initPaginationState);
    const [filter, setFilter] = useState(initFilterState);

    useEffect(() => {
        contentCtx.updatePath([{ title: 'Resources', link: '/resources' }]);
        loadData(filter);
    }, [currentPage.page, currentPage.size, filter.selectedStatus, filter.text]);

    const loadData = (filterValues) => {
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        axios.get(URL,
            {
                params: {
                    page: currentPage.page,
                    size: currentPage.size,
                    status: filterValues.selectedStatus.code,
                    text: filterValues.text
                },
            }).then((result) => {
                //console.log("GetFromServer", result.data);
                setState({
                    ...state, data: formatData(result.data.data), loading: false, msg: null
                });
                setCurrentPage({ ...currentPage, total: result.data.total });
            }).catch((error) => {
                console.log('error:', error);
                if (error.response && error.response.status === 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state, data: [], loading: false, msg: getErrorDesc(error)
                    });
                }
            });
    };



    const searchText = (text) => {
        console.log('searchText', text);
        setFilter({ ...filter, text });
    };

    const getStatus = (c) => {
        let result = statusList.find(status => status.code == c);
        if (result)
            return result;
        else {
            console.log('Status not found!');
            return { code: -1, name: 'ALL' };
        }

    };

    const changeStatus = (selectedStatusCode) => {
        const selectedStatusItem = getStatus(selectedStatusCode);
        setFilter({
            ...filter,
            selectedStatus: selectedStatusItem
        });
    };

    const changeCurrentPage = (page) => {
        setCurrentPage({ ...currentPage, page });
    };
    const changePageSize = (size) => {
        setCurrentPage({ ...currentPage, size });
    };

    const formatData = (data) => {
        return data.map((r) => {
            return {
                ...r,
                status: getStatus(r.status).name,
                dob: date2str(r.dob),
            };
        });
    };

    const showDetails = (el) => {
        navigate(`${"/resources/patient"}/${el.id}`);

    }

    const activate = (el) => {
        //navigate(`${URL}/${el.id}`);
        console.log(el.status, "sdadsadadasda");

        var operation = "activate";
        if (el.status === "Active") {
            operation = "de-activate";
        }

        var text = 'Are you sure that you want to ' + operation + " User " + el.id + "?";
        let respond = window.confirm(text);
        console.log(respond);

        if (!respond) {
            return;
        }

        //ToDo
        //call an api to backend to generate a pwd and activate account in db, then send an email

        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;

        var URL;
        if (el.status === "Active") {
            URL = "/user/deactivateuser";
        }
        else {
            URL = "/user/activateuser";
        }
        axios.post(URL, { "uid": el.id }
        ).then((result) => {
            if (result.status === 200) {
                //console.log("success");
                //console.log(result);
                //alert(result.data.message);
                loadData(filter);
                alert(result.data.message);
            }

        }).catch((error) => {
            if (error.response) {
                alert(error.response.data.message); // => the response payload 
            }

        });

        //alert("hello");

    };

    return (
        <>
            <div className="row mb-6" >
                <div className="col-6">
                    <SearchBox findText={searchText} />
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <Dropdown onSelect={changeStatus}>
                            <Dropdown.Toggle id="basicItemCategory-basic" >
                                {filter.selectedStatus.name}
                            </Dropdown.Toggle >
                            <Dropdown.Menu>
                                {
                                    statusList.map((c) => {
                                        return (<Dropdown.Item key={c.code} eventKey={c.code}>{c.name}</Dropdown.Item>);
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>

            <div className="row mb-6 p-10" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div className="col-12" style={{ flex: '1', overflow: 'hidden' }}>
                    {/* Table container */}
                    <div style={{ position: 'relative', width: '100%', overflowX: 'auto' }}>
                        <DataTable
                            module={URL}
                            id='id'
                            status='status'
                            list={{
                                data: state.data,
                                columns: cols,
                                labels: titles
                            }}
                            view={{
                                label: 'DETAILS',
                                colSize: 1,
                                buttonSize: 12,
                                buttons: [
                                    {
                                        label: 'Resources',
                                        func: showDetails
                                    }],
                            }}
                        // view2={{
                        //     label: "Activation",
                        //     colSize: 1,
                        //     buttonSize: 12,
                        //     buttons: [
                        //         {

                        //             func: activate
                        //         }],
                        // }}

                        />
                    </div>
                </div>

                {/* Paginator, fixed below the table and sliding with the scroll bar */}
                <div style={{ width: '100%', paddingTop: '10px', textAlign: 'center', overflowX: 'auto', whiteSpace: 'nowrap' }}>
                    <DataTablePagination page={currentPage.page} size={currentPage.size} total={currentPage.total} setPage={changeCurrentPage} setSize={changePageSize} />
                </div>
                
                <Spinner value={state.loading} />
                {state.msg !== null && <MsgBox value={{ type: 'error', title: '', msg: state.msg }} />}
            </div>
        </>
    );
}
